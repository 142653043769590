import React, { useEffect, useState, useRef } from 'react'
import * as R from 'ramda'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import { useCreateImageMutation } from 'common/hook/createImage'
import { useGetStyng } from 'utils/useGetStyng'
import { handleStyngUpload } from 'utils/handleStyngUpload'
import useStores from 'common/hook/useStore'

import { Toolbar, Typography, Box } from '@mui/material'

import Spinner from 'ui/Spinner/Spinner'
import Button, { ButtonVariant } from 'ui/Button/Button'
import TextField from 'ui/TextField/TextField'

import Text from 'locale/strings'
import API from 'common/api'
import { useRoyaltyFreeStyngQuery } from '../../../styngs/CreateStyng/CreateStyngStore'
import { RoyaltyFreeStyngRequest, RoyaltyFreeStyngResponse } from 'common/api/styng/styng'
import { ImageEntity } from 'common/api/image/image'
import { defaultImg, FileType } from 'common/constants'
import useImage from 'common/hook/useImage'
import { StyngsRoute } from 'styngs'

import styles from './RoyaltyFreeStyng.module.scss'

interface ParamProps {
  id?: string
}

const schema = yup.object({
  name: yup.string().max(50, 'Name must be at most 50 characters').required('Name is required'),
})

interface StyngFormValues {
  name: string
  trackName: string
  artists: string
  albumName: string
  imageId: Nullable<string>
  fileId: string
}

const initialValues: StyngFormValues = {
  name: '',
  trackName: '',
  artists: '',
  albumName: '',
  imageId: null,
  fileId: '',
}

const RoyaltyFreeStyng = () => {
  const { navigationStore, notificationsStore, editStyngStore } = useStores()
  const { id = '' } = useParams<ParamProps>()
  const [styngError, setStyngError] = useState<boolean>(false)
  const [styngFormatError, setStyngFormatError] = useState<boolean>(false)
  const [url, setUrl] = useState('')
  const [initialUrl, setInitialUrl] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [royaltyFreeStyng, setRoyaltyFreeStyng] = useState<Nullable<File>>(null)
  const { data: initialStyng } = useRoyaltyFreeStyngQuery(id)
  const audioRef = useRef<any>(null)

  const mutationRoyaltyFreeStyng = useMutation<RoyaltyFreeStyngResponse, Error, RoyaltyFreeStyngRequest>(
    (body: RoyaltyFreeStyngRequest) => {
      if (!R.isNil(initialStyng)) {
        return API.styngs.editRoyaltyFree({ ...body, id: initialStyng.id })
      }

      return API.styngs.createRoyaltyFree(body)
    },
    {
      onSuccess: () => {
        editStyngStore.setTrack(null)
        navigationStore.goToPage(StyngsRoute.path)

        !R.isNil(initialStyng)
          ? notificationsStore.successNotification('Styng successfully edited')
          : notificationsStore.successNotification('Styng successfully added')
      },
      onError: (error) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const mutationImage = useCreateImageMutation((data: ImageEntity) => {
    formik.setFieldValue('imageId', data.id)
  })

  const handleSubmitRoyaltyFreeStyng = async (values: StyngFormValues) => {
    setIsLoading(true)

    if (!url) {
      setStyngError(true)
      setIsLoading(false)

      return false
    }

    setStyngError(false)

    if (initialUrl === url) {
      mutationRoyaltyFreeStyng.mutate({
        name: values.name.trim(),
        trackName: values.trackName.trim(),
        artists: values.artists.trim(),
        albumName: values.albumName.trim(),
        imageId: values.imageId,
        fileId: values.fileId,
      })

      setIsLoading(false)

      return false
    }

    const sendForm = (imageId: Nullable<string>) => {
      let uploadId: string
      let uploadUrl: string

      const getDataForStyngUpload = API.general.getUploadData({
        fileContentType: FileType.STYNG,
      })

      getDataForStyngUpload
        .then((response) => {
          uploadId = response.id
          uploadUrl = response.uploadUrl

          return fetch(uploadUrl, {
            method: 'PUT',
            body: royaltyFreeStyng,
          })
        })
        .then(() => {
          setIsLoading(false)

          mutationRoyaltyFreeStyng.mutate({
            name: values.name.trim(),
            trackName: values.trackName.trim(),
            artists: values.artists.trim(),
            albumName: values.albumName.trim(),
            imageId: imageId,
            fileId: uploadId,
          })
        })
    }

    sendForm(values.imageId)
  }

  useEffect(() => {
    if (!R.isNil(royaltyFreeStyng)) {
      const url = URL.createObjectURL(royaltyFreeStyng)

      setUrl(url)

      if (audioRef.current) {
        audioRef.current.load()
      }
    }
  }, [royaltyFreeStyng])

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]

      if (file.type.includes('image/')) {
        const formData = new FormData()

        formData.append('file', file)

        mutationImage.mutate(formData)
      }
    }
  }

  const mutationGetStyng = useGetStyng((data: any) => {
    setUrl(data)
    setInitialUrl(data)
  })

  const formik = useFormik<StyngFormValues>({
    initialValues: initialValues,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmitRoyaltyFreeStyng,
  })

  const { values, resetForm } = formik
  const { data } = useImage(values.imageId || '')
  const imageStyng = data?.url ?? defaultImg

  useEffect(() => {
    if (!R.isNil(initialStyng)) {
      resetForm({
        values: {
          name: initialStyng.name,
          trackName: initialStyng.trackName,
          artists: initialStyng.artists,
          albumName: initialStyng.albumName,
          imageId: initialStyng.imageId,
          fileId: initialStyng.fileId,
        },
      })
    }
  }, [initialStyng, resetForm])

  useEffect(() => {
    if (id !== '') {
      mutationGetStyng.mutate({ id: id })
    }
  }, [])

  return (
    <Box>
      {id && R.isNil(initialStyng) ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <Toolbar className={styles.toolbar}>
            <Typography sx={{ ml: 2, flex: 1, color: '#fff' }} variant="h6" component="div">
              {!R.isNil(initialStyng) ? Text.page.styngs.editStyng : Text.page.styngs.newStyng}
            </Typography>
          </Toolbar>
          <div className={styles.container}>
            <div className={styles.form}>
              <TextField
                fullWidth
                data-test="name-field"
                label={Text.page.styngs.create.fields.title}
                name="name"
                value={values.name}
                error={formik.errors.name}
                onChange={formik.handleChange}
              />
              <TextField
                fullWidth
                data-test="artists-field"
                label={Text.page.styngs.create.fields.artist}
                name="artists"
                value={values.artists}
                onChange={formik.handleChange}
              />
              <TextField
                fullWidth
                data-test="album-name-field"
                label={Text.page.styngs.create.fields.album}
                name="albumName"
                value={values.albumName}
                onChange={formik.handleChange}
              />
              <TextField
                fullWidth
                data-test="track-name-field"
                label={Text.page.styngs.create.fields.trackName}
                name="trackName"
                value={values.trackName}
                onChange={formik.handleChange}
              />
              <div className={styles.filesWrapper}>
                <div className={styles.uploadCover}>
                  <label htmlFor="file-styng-id">
                    <input
                      hidden
                      id="file-styng-id"
                      type="file"
                      accept="audio/aac, audio/mp3"
                      value=""
                      onChange={(event) =>
                        handleStyngUpload(event, setStyngError, setStyngFormatError, setRoyaltyFreeStyng)
                      }
                    />
                    <Button isUpload data-test="upload-styng-button">
                      {Text.page.styngs.create.uploadStyng}
                    </Button>
                    <If condition={styngError && R.isNil(initialStyng)}>
                      <span className={styles.customError}>{Text.page.styngs.styngError}</span>
                    </If>
                    <If condition={styngFormatError}>
                      <span className={styles.customError}>{Text.page.styngs.supportedFiles}</span>
                    </If>
                  </label>
                  <If condition={!R.isNil(royaltyFreeStyng)}>
                    <span className={styles.fileName}>{royaltyFreeStyng?.name}</span>
                  </If>

                  {url && (
                    <audio controls controlsList="nodownload" ref={audioRef} className={styles.audioPreview}>
                      <source src={url} />
                    </audio>
                  )}
                </div>
                <div className={styles.uploadImage}>
                  {imageStyng !== defaultImg && <img src={imageStyng} alt="styngImage" />}
                  <label htmlFor="file-image-id">
                    <input
                      hidden
                      id="file-image-id"
                      type="file"
                      accept="image/*"
                      value=""
                      onChange={handleImageUpload}
                    />
                    <Button isUpload data-test="upload-image-button">
                      {Text.page.styngs.create.uploadCover}
                    </Button>
                  </label>
                </div>
              </div>
            </div>

            <div className={styles.submitContainer}>
              <Button
                data-test="cancel-button"
                variant={ButtonVariant.OUTLINED}
                onClick={() => navigationStore.goToPage(StyngsRoute.path)}
              >
                {Text.common.cancel}
              </Button>
              <Button data-test="submit-button" loading={isLoading} onClick={formik.handleSubmit}>
                {Text.common.save}
              </Button>
            </div>
          </div>
        </React.Fragment>
      )}
    </Box>
  )
}

export default RoyaltyFreeStyng
