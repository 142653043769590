/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useMutation } from 'react-query'

import { useStyngsQuery } from 'styngs/StyngsStore'

import { SoundboardRoute } from '../..'

import { DateTime } from 'luxon'

import useStores from '../../../common/hook/useStore'

import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

import { Tab, Toolbar, Typography, Box } from '@mui/material'

import ConfirmationModal from 'ui/ConfirmationModal/ConfirmationModal'
import Spinner from 'ui/Spinner/Spinner'

import { useSoundboardQuery } from './../../SoundboardStore'

import Text from 'locale/strings'

import API from 'common/api'
import { SoundboardApplication, Application } from 'common/api/app/app'
import { SoundboardGeneral, Category } from 'common/api/soundboard/soundboard'
import { SoundboardStyng } from 'common/api/styng/styng'
import { ApiStatuses, EditSoundboardTabs } from 'common/constants'
import { ImageEntity } from 'common/api/image/image'
import { useCreateImageMutation } from 'common/hook/createImage'

import General from './../../components/General/General'
import BindApplications from './../../components/BindApplications/BindApplications'
import Categories from './../../components/Categories/Categories'

import styles from './EditSoundboard.module.scss'

interface ParamProps {
  id?: string
}

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^\S.*\S$|^\S$/, 'No leading or trailing spaces are allowed')
    .required('Name is required')
    .max(50, 'Name must be at most 50 characters'),
  subscriptionPrice: yup
    .number()
    .typeError('Must be a number')
    .min(0, 'Field value must be greater than or equal to 0'),
  styngPrice: yup.number().typeError('Must be a number').min(0, 'Field value must be greater than or equal to 0'),
})

const EditSoundboard = () => {
  const { navigationStore, notificationsStore, styngsStore } = useStores()
  const [tabValue, setTabValue] = useState(EditSoundboardTabs.GENERAL_INFORMATION_TAB)
  const { id = '' } = useParams<ParamProps>()
  const { data: soundboard, status: soundboardStatus } = useSoundboardQuery(id)

  const { data: styngs } = useStyngsQuery()
  const styngsPagesCount = styngs?.pagesCount ?? 1

  const [selectedApps, setSelectedApps] = useState<Application[]>([])

  const [categoriesList, setCategoriesList] = useState<Category[]>([])

  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
  const [confirmationFlow, setConfirmationFlow] = useState<string>('')
  const [confirmationData, setConfirmationData] = useState<any>()

  const mutationEditSoundboardGeneralInfo = useMutation<any, Error, any>(
    (body: any) => {
      return API.soundboard.editGeneralInfo(body)
    },
    {
      onSuccess: () => {
        resetForm()
        navigationStore.goToPage(SoundboardRoute.path)

        notificationsStore.successNotification('Soundboard successfully edited')
      },
      onError: (error) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const mutationCreateSoundboardCategory = useMutation(
    (body: any) => {
      return API.soundboard.createSoundboardCategory(body)
    },
    {
      onSuccess: (data) => {
        setCategoriesList((currentCategoriesList) => [
          { id: data.id, name: data.name, styngs: [] },
          ...currentCategoriesList,
        ])

        notificationsStore.successNotification('Category successfully added')
      },
      onError: (error) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const mutationDeleteSoundboardCategory = useMutation(
    (body: any) => {
      return API.soundboard.deleteSoundboardCategory(body)
    },
    {
      onSuccess: (_, variables) => {
        setCategoriesList((categoryArray) =>
          categoryArray.filter((categoryOption) => categoryOption.id !== variables.categoryId),
        )

        notificationsStore.successNotification('Category successfully deleted')
      },
      onError: (error) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const mutationAddStyngToSoundboardCategory = useMutation(
    (body: any) => {
      return API.soundboard.addStyngToSoundboardCategory(body)
    },
    {
      onSuccess: (data) => {
        setCategoriesList(data.categories)

        notificationsStore.successNotification('Styng successfully added')
      },
      onError: (error) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const mutationDeleteStyngFromSoundboardCategory = useMutation(
    (body: any) => {
      return API.soundboard.deleteStyngFromSoundboardCategory(body)
    },
    {
      onSuccess: (data) => {
        setCategoriesList(data.categories)

        notificationsStore.successNotification('Styng successfully removed')
      },
      onError: (error) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const mutationExpirationDateStyngFromSoundboardCategory = useMutation(
    (body: any) => {
      return API.soundboard.updateStyngExpirationDateInSoundboardCategory(body)
    },
    {
      onSuccess: (data) => {
        setCategoriesList(data.categories)

        notificationsStore.successNotification('Styng expiration date successfully changed')
      },
      onError: (error) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const mutationBindApp = useMutation(
    (body: any) => {
      return API.soundboard.bindSoudboardToApp(body)
    },
    {
      onSuccess: (data) => {
        setSelectedApps(data.applications)

        notificationsStore.successNotification('Application successfully bound')
      },
      onError: (error) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const mutationUnbindApp = useMutation(
    (body: any) => {
      return API.soundboard.unbindSoudboardToApp(body)
    },
    {
      onSuccess: (data) => {
        setSelectedApps(data.applications)

        notificationsStore.successNotification('Application successfully unbound')
      },
      onError: (error) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const mutationPublishApp = useMutation(
    (body: any) => {
      return API.soundboard.publishAppWithinSoudboard(body)
    },
    {
      onSuccess: (_, variables) => {
        setShowConfirmationModal(false)
        updatePublishAfterMutation(variables.appId)

        notificationsStore.successNotification('Application successfully published')
      },
      onError: (error) => {
        setShowConfirmationModal(false)
        notificationsStore.errorNotification(error)
      },
    },
  )

  const mutationUnpublishApp = useMutation(
    (body: any) => {
      return API.soundboard.unpublishAppWithinSoudboard(body)
    },
    {
      onSuccess: (_, variables) => {
        setShowConfirmationModal(false)
        updatePublishAfterMutation(variables.appId)

        notificationsStore.successNotification('Application successfully unpublished')
      },
      onError: (error) => {
        setShowConfirmationModal(false)
        notificationsStore.errorNotification(error)
      },
    },
  )

  const [initialValues, setInitialValues] = useState({
    id: '',
    name: '',
    subscriptionPrice: '',
    styngPrice: '',
    sourceType: 'LSR',
    imageId: '',
  })

  useEffect(() => {
    if (typeof soundboard !== 'undefined') {
      setInitialValues({
        id: soundboard.id,
        name: soundboard.name,
        subscriptionPrice: soundboard.subscriptionPrice,
        styngPrice: soundboard.styngPrice,
        sourceType: 'LSR',
        imageId: soundboard.imageId,
      })

      setSelectedApps(soundboard.apps)
      setCategoriesList(soundboard.categories)
    }
  }, [soundboard])

  const handleTabChange = (_event: React.SyntheticEvent, newValue: EditSoundboardTabs) => {
    setTabValue(newValue)
  }

  const mutationImage = useCreateImageMutation((data: ImageEntity) => {
    formik.setFieldValue('imageId', data.id)
  })

  // General
  const handleSoundboardImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]

      if (file.type.includes('image/')) {
        const formData = new FormData()

        formData.append('filename', file.name)
        formData.append('file', file)

        mutationImage.mutate(formData)
      }
    }
  }

  // handle categories
  const addNewCategory = (categoryName: string) => {
    if (categoryName.length === 0 || categoriesList.some((category) => category.name === categoryName)) {
      return
    }

    mutationCreateSoundboardCategory.mutate({
      soundboardId: soundboard.id,
      name: categoryName,
    })
  }

  const removeCategory = (categoryIndex: number) => {
    mutationDeleteSoundboardCategory.mutate({
      soundboardId: soundboard.id,
      categoryId: categoriesList[categoryIndex].id,
    })
  }

  const handleStyngsChangePage = (value: number) => {
    styngsStore.changePage(value)
  }

  const addStyngToCategory = (styng: SoundboardStyng, selectedCategoryIndex: number | null) => {
    if (selectedCategoryIndex === null) {
      return
    }

    mutationAddStyngToSoundboardCategory.mutate({
      soundboardId: soundboard.id,
      categoryId: categoriesList[selectedCategoryIndex].id,
      styngId: styng.id,
    })
  }

  const removeStyngFromCategory = (styngId: string, selectedCategoryIndex: number) => {
    if (selectedCategoryIndex === null) {
      return
    }

    mutationDeleteStyngFromSoundboardCategory.mutate({
      soundboardId: soundboard.id,
      categoryId: categoriesList[selectedCategoryIndex].id,
      styngId: styngId,
    })
  }

  const setExpirationDate = (value: DateTime, styngId: string, selectedCategoryIndex: number) => {
    mutationExpirationDateStyngFromSoundboardCategory.mutate({
      soundboardId: soundboard.id,
      categoryId: categoriesList[selectedCategoryIndex].id,
      styngId: styngId,
      newExpirationDate: value.toFormat('yyyy-MM-dd'),
    })
  }

  const handleChangeApps = (_: React.SyntheticEvent, value: Application[]) => {
    const oldSet = new Set(selectedApps.map((item) => item.id))
    const newSet = new Set(value.map((item) => item.id))

    const removed = selectedApps.filter((item) => !newSet.has(item.id))
    const added = value.filter((item) => !oldSet.has(item.id))

    added.forEach((item) =>
      mutationBindApp.mutate({
        soundboardId: soundboard.id,
        appId: item.id,
      }),
    )

    removed.forEach((item) =>
      mutationUnbindApp.mutate({
        soundboardId: soundboard.id,
        appId: item.id,
      }),
    )
  }

  const handleDeleteApp = (value: string) => {
    mutationUnbindApp.mutate({
      soundboardId: soundboard.id,
      appId: value,
      selectedAppsAfterMutation: selectedApps.filter((appOption) => appOption.id !== value),
    })
  }

  const handleAppPublishToggle = (app: SoundboardApplication) => {
    const currentDay = DateTime.now()
    let styngExpireAlert = false

    const allDates: any[] = []

    categoriesList.forEach((category) => {
      category.styngs?.forEach((styng) => {
        if (typeof styng.expiresAt !== 'undefined') {
          allDates.push(styng.expiresAt)
        }
      })
    })

    allDates.forEach((date) => {
      const selectedDate = DateTime.fromISO(date)
      const range = Math.abs(currentDay.diff(selectedDate, 'days').days)

      if (range < 7) {
        return (styngExpireAlert = true)
      }
    })

    if (app.published) {
      mutationUnpublishApp.mutate({
        soundboardId: soundboard.id,
        appId: app.id,
      })
    } else {
      if (styngExpireAlert) {
        setShowConfirmationModal(true)
        setConfirmationFlow('publish')

        setConfirmationData({
          soundboardId: soundboard.id,
          appId: app.id,
        })
      } else {
        mutationPublishApp.mutate({
          soundboardId: soundboard.id,
          appId: app.id,
        })
      }
    }
  }

  const updatePublishAfterMutation = (appId: string) => {
    setSelectedApps((appArray) =>
      appArray.map((item: any) =>
        item.id === appId
          ? {
              ...item,
              published: item.published === undefined || item.published === false ? true : !item.published,
            }
          : item,
      ),
    )
  }

  const handleSubmit = (values: SoundboardGeneral) => {
    mutationEditSoundboardGeneralInfo.mutate(values)
  }

  const handleSubmitGeneralInfo = () => {
    formik.handleSubmit()
  }

  const formik = useFormik<SoundboardGeneral>({
    initialValues: initialValues,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  })

  const { handleChange, errors, values, resetForm } = formik

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false)
  }

  const handleConfirmation = () => {
    if (confirmationFlow === 'publish') {
      mutationPublishApp.mutate({
        soundboardId: confirmationData.soundboardId,
        appId: confirmationData.appId,
      })
    }
  }

  return (
    <React.Fragment>
      <Toolbar className={styles.toolbar}>
        <Typography sx={{ ml: 2, flex: 1, color: '#fff' }} variant="h6" component="div">
          {Text.navigation.editSoundboard}
        </Typography>
      </Toolbar>
      <Box>
        {soundboardStatus === ApiStatuses.LOADING ? (
          <Spinner />
        ) : (
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList aria-label="Playlist page tabs" onChange={handleTabChange}>
                <Tab label="General" value={EditSoundboardTabs.GENERAL_INFORMATION_TAB} />
                <Tab label="Categories" value={EditSoundboardTabs.CATEGORIES_TAB} />
                <Tab label="Bound applications" value={EditSoundboardTabs.APPS_TAB} />
              </TabList>
            </Box>
            <TabPanel value={EditSoundboardTabs.GENERAL_INFORMATION_TAB}>
              <General
                editFlow
                handleChange={handleChange}
                errors={errors}
                values={values}
                handleSoundboardImageUpload={handleSoundboardImageUpload}
                handleSubmitGeneralInfo={handleSubmitGeneralInfo}
              />
            </TabPanel>
            <TabPanel value={EditSoundboardTabs.CATEGORIES_TAB}>
              {styngs && (
                <Categories
                  styngs={styngs}
                  categoriesList={categoriesList}
                  addNewCategory={addNewCategory}
                  removeCategory={removeCategory}
                  addStyngToCategory={addStyngToCategory}
                  setExpirationDate={setExpirationDate}
                  removeStyngFromCategory={removeStyngFromCategory}
                  styngsStore={styngsStore}
                  styngsPagesCount={styngsPagesCount}
                  handleStyngsChangePage={handleStyngsChangePage}
                />
              )}
            </TabPanel>
            <TabPanel value={EditSoundboardTabs.APPS_TAB}>
              <BindApplications
                withPublish
                selectedApps={selectedApps}
                handleChangeApps={handleChangeApps}
                handleDeleteApp={handleDeleteApp}
                handleAppPublishToggle={handleAppPublishToggle}
              />
            </TabPanel>
          </TabContext>
        )}
      </Box>
      {showConfirmationModal && (
        <ConfirmationModal
          open={showConfirmationModal}
          headerContent={'Please confirm'}
          handleClose={handleCloseConfirmationModal}
          text={'One or more styngs expire in less than seven days. Do you want to proceed?'}
          handleSubmit={handleConfirmation}
        />
      )}
    </React.Fragment>
  )
}

export default thread(EditSoundboard, [observer])
