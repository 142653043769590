/* eslint-disable jsx-control-statements/jsx-for-require-each */
import React, { useState } from 'react'
import { Duration } from 'luxon'

import { useStyngDeactivate } from 'common/hook/useStyngDeactivate'
import { useStyngActivate } from 'common/hook/useStyngActivate'

import Spinner from 'ui/Spinner/Spinner'
import Table from 'ui/Table/Table'
import Pagination from 'ui/Pagination/Pagination'
import JsonDataModalWithPlatform from 'ui/JsonDataModalWithPlatform/JsonDataModalWithPlatform'

import { Styng } from 'common/api/styng/styng'
import { formatGenres } from 'common/hook/formatGenre'
import useStores from 'common/hook/useStore'
import { truncateText } from 'common/utils'
import { FileType } from 'common/constants'

import EditStyngStore from '../../../styngs/CreateStyng/CreateStyngStore'
import NftStore from '../../NftModal/NftStore'

import StyngModal from '../../StyngModal/StyngModal'
import NftModal from '../../NftModal/NftModal'

import styles from './StyngsLsrTable.module.scss'

interface StyngsProps {
  styngs: Styng[]
  pagesCount: number
  refetch: () => void
}

const StyngsLsrTable = ({ styngs, pagesCount, refetch }: StyngsProps) => {
  const { styngsStore, editStyngStore } = useStores()

  const createStyngStore = new EditStyngStore()
  const createNftStore = new NftStore()

  const [selectedStyngId, setSelectedStyngId] = useState<string>('')
  const [selectedStyng, setSelectedStyng] = useState<Styng | null>(null)
  // Add modal / Edit modal
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showNftModal, setShowNftModal] = useState<boolean>(false)
  const [jsonDataModal, setJsonDataModal] = useState<boolean>(false)

  //pagination check for every new render of styngs page to start from 1
  React.useEffect(() => {
    return () => {
      styngsStore.changePage(1)
    }
  }, [])

  const handleEditClick = (id: string) => {
    setSelectedStyngId(id)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    editStyngStore.setTrack(null)
    setShowModal(false)
    setSelectedStyngId('')
  }

  //NFT modal
  const handleCreateNftClick = (id: string) => {
    setSelectedStyngId(id)
    setShowNftModal(true)
  }

  const handleCloseNftModal = () => {
    setSelectedStyngId('')
    setShowNftModal(false)
  }

  // Pagination logic
  const handleChangePage = (value: number) => {
    styngsStore.changePage(value)
  }

  // Table data
  const columns = [
    'Image',
    'Id',
    'ISRC',
    'Song name',
    'Artist',
    'Styng name',
    'Genres',
    'Duration',
    'Published',
    'External Metadata',
    'Actions',
  ]

  const dataTable = styngs.map((styng: Styng) => [
    styng.imageUrl,
    styng.id,
    styng.trackIsrc,
    styng.trackTitle,
    styng.artist,
    truncateText(styng.name),
    formatGenres(styng.genres),
    Duration.fromISO(styng.duration).toFormat('mm:ss'),
    styng.isActive.toString(),
    Object.keys(styng.metadata).length === 0 ? 'NULL' : '',
  ])

  const onActivateLsrMutation = useStyngActivate((_: any) => {
    refetch()
  })

  const onDeactivateLsrMutation = useStyngDeactivate((_: any) => {
    refetch()
  })

  const handleActivate = (id: string, active: string) => {
    if (active === 'false') {
      onActivateLsrMutation.mutate({ styngId: id })
    } else {
      onDeactivateLsrMutation.mutate({ styngId: id })
    }
  }

  const handleOpenAdJsonModal = (id: string) => {
    const styng = styngs.filter((styng: Styng) => styng.id === id)

    setSelectedStyng(styng[0])
    setJsonDataModal(true)
  }

  const handleCloseAdJsonModal = () => {
    setSelectedStyng(null)
    setJsonDataModal(false)
  }

  return (
    <div className={styles.container}>
      {(onActivateLsrMutation.isLoading || onDeactivateLsrMutation.isLoading) && <Spinner />}

      <Table
        jsonData
        hideDelete
        columns={columns}
        data={dataTable}
        hiddenColumns={['Id', 'External Metadata']}
        publishedToggle={true}
        nftIcon={true}
        handleActivate={handleActivate}
        onEditClick={handleEditClick}
        onCreateNftClick={handleCreateNftClick}
        onJsonDataClick={handleOpenAdJsonModal}
      />

      <If condition={pagesCount > 1}>
        <div className={styles.paginationContainer}>
          <div className={styles.pagination}>
            <Pagination page={styngsStore.page} count={pagesCount} onChange={handleChangePage} />
          </div>
        </div>
      </If>

      {showModal && (
        <StyngModal
          editStyngStore={selectedStyngId === '' ? createStyngStore : editStyngStore}
          styngId={selectedStyngId}
          open={showModal}
          handleClose={handleCloseModal}
        />
      )}

      {showNftModal && (
        <NftModal
          nftStore={createNftStore}
          refetch={refetch}
          styngId={selectedStyngId}
          open={showNftModal}
          handleClose={handleCloseNftModal}
        />
      )}

      {jsonDataModal && (
        <JsonDataModalWithPlatform
          dataTypeId={selectedStyng?.id}
          data={selectedStyng?.metadata}
          dataType={FileType.STYNG}
          open={jsonDataModal}
          refetch={refetch}
          handleClose={handleCloseAdJsonModal}
        />
      )}
    </div>
  )
}

export default StyngsLsrTable
