import { useState } from 'react'
import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'
import { useStyngsQuery, useRoyaltyFreeStyngsQuery } from '../../StyngsStore'

import StyngsLsrTable from './../../components/StyngsLsrTable/StyngsLsrTable'
import StyngsRoyaltyFreeTable from './../../components/StyngsRoyaltyFreeTable/StyngsRoyaltyFreeTable'
import LoadingRemote from 'common/LoadingRemote'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Box, Tab } from '@mui/material'
import { StyngPageTabs } from 'common/constants'

import styles from './StyngsPage.module.scss'

const StyngsPage = () => {
  const [tabValue, setTabValue] = useState(StyngPageTabs.LSR_TAB)
  const { data: dataLsr, refetch: refetchLsr, status } = useStyngsQuery()
  const { data: dataRf, refetch: refetchRf } = useRoyaltyFreeStyngsQuery()

  const styngsLsr = dataLsr?.items ?? []
  const pagesCountLsr = dataLsr?.pagesCount ?? 1

  const styngsRf = dataRf?.items ?? []
  const pagesCountRf = dataRf?.pagesCount ?? 1

  const handleTabChange = (_: React.SyntheticEvent, newValue: StyngPageTabs) => {
    setTabValue(newValue)

    if (newValue === StyngPageTabs.LSR_TAB) {
      refetchLsr()
    } else {
      refetchRf()
    }
  }

  return (
    <TabContext value={tabValue}>
      <Box className={styles.tabs}>
        <TabList centered variant="fullWidth" aria-label="Playlist page tabs" onChange={handleTabChange}>
          <Tab label="LSR " value={StyngPageTabs.LSR_TAB} />
          <Tab label="Royalty Free" value={StyngPageTabs.RF_TAB} />
        </TabList>
      </Box>
      <TabPanel sx={{ padding: 0 }} value={StyngPageTabs.LSR_TAB}>
        <div>
          <LoadingRemote status={status}>
            <StyngsLsrTable styngs={styngsLsr} pagesCount={pagesCountLsr} refetch={refetchLsr} />
          </LoadingRemote>
        </div>
      </TabPanel>
      <TabPanel sx={{ padding: 0 }} value={StyngPageTabs.RF_TAB}>
        <div>
          <StyngsRoyaltyFreeTable styngs={styngsRf} pagesCount={pagesCountRf} refetch={refetchRf} />
        </div>
      </TabPanel>
    </TabContext>
  )
}

export default thread(StyngsPage, [observer])
